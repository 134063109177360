import React, { useEffect, useState } from 'react';
import {
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBInput,
  MDBTextArea,
  MDBValidation,
} from 'mdb-react-ui-kit';
import emailjs from '@emailjs/browser';

const formValueTemplate = {
  user_email: '',
  subject: '',
  text: '',
};

export default function ContactForm() {
  const [centredModal, setCentredModal] = useState(false);
  const [formValue, setFormValue] = useState(formValueTemplate);
  const toggleShow = () => setCentredModal(!centredModal);

  const EMAILJS_PUBLIC_KEY = 'JuTB0s3qi4kdqynOs';
  const EMAILJS_TEMPLATE_ID = 'template_pg0os4s';
  const EMAILJS_SERVICE_ID = 'service_v7d41ma';

  useEffect(() => {
    emailjs.init(process.env.EMAILJS_PUBLIC_KEY);
    console.log('public key ' + process.env.EMAILJS_PUBLIC_KEY);
  }, []);

  console.log('formValue');
  console.log(formValue);

  const sendEmail = (e) => {
    e.preventDefault();

    console.log('formValue');
    console.log(formValue);

    emailjs
      .send(
        EMAILJS_SERVICE_ID,
        EMAILJS_TEMPLATE_ID,
        formValue,
        EMAILJS_PUBLIC_KEY
      )
      .then(
        function (response) {
          console.log('SUCCESS!', response.status, response.text);
        },
        function (error) {
          console.log('FAILED...', error);
        }
      );
    toggleShow();
    setFormValue(formValueTemplate);
  };

  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  // const validEmail = new RegExp('/^[^s@]+@[^s@]+.[^s@]+$/');

  return (
    <>
      <MDBBtn
        floating
        tag="a"
        className="contact-form-btn"
        size="lg"
        color="warning"
        onClick={toggleShow}
      >
        <MDBIcon fas icon="comment" size="2x" className="contact-icon" />
      </MDBBtn>
      <MDBModal tabIndex="-1" show={centredModal} setShow={setCentredModal}>
        <MDBModalDialog centered>
          <MDBModalContent className="contact-form-modal">
            <MDBModalHeader>
              <MDBModalTitle>Contact form</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBValidation>
              <MDBModalBody>
                <form>
                  {/* <form ref={form} onSubmit={sendEmail}> */}
                  <MDBInput
                    className="mb-4"
                    type="subject"
                    name="subject"
                    id="formSubject"
                    label="Subject"
                    onChange={onChange}
                  />
                  <MDBInput
                    className="mb-4"
                    type="email"
                    id="formEmail"
                    name="user_email"
                    label="Your Email"
                    onChange={onChange}
                  />
                  <MDBTextArea
                    className="mb-4"
                    type="message"
                    id="formMessage"
                    name="text"
                    label="Message"
                    onChange={onChange}
                    rows={3}
                  />
                  <MDBBtn block color="warning" onClick={sendEmail}>
                    Send
                  </MDBBtn>
                </form>
              </MDBModalBody>
            </MDBValidation>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
