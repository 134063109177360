import React from 'react';
import Box from '@mui/material/Box';
import { facebookUrl, instagramUrl } from '../constants/urls';

const SocialSidebar = () => {
  return (
    <Box
      sx={{ flexgrow: 1, maxWidth: '100%', mx: 'auto' }}
      className="socialbar-root"
    >
      <div className="social-bar">
        <a
          href={facebookUrl}
          target="_blank"
          className="facebook"
          rel="noreferrer"
        >
          <i className="fab fa-facebook-f" />
        </a>
        <a
          href="https://twitter.com/kfisher9ball?lang=en"
          className="twitter"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-twitter" />
        </a>
        <a
          href={instagramUrl}
          className="instagram"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-instagram" />
        </a>
        <a
          href="https://www.youtube.com/@kellyfisher7349"
          className="youtube"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-youtube" />
        </a>
      </div>
    </Box>
  );
};

export default SocialSidebar;
