import { Box } from '@mui/material';
import React from 'react';
import headerBanner from '../../media/kelly-banner2.jpg';

const Header = () => {
  return (
    <>
      <Box
        component="img"
        sx={{
          width: '100%',
          objectFit: 'cover',
          mb: 0,
        }}
        src={headerBanner}
      />
    </>
  );
};

export default Header;
