import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit';
import About from './components/About';
import Footer from './components/layout/Footer';
import Header from './components/layout/Header';
import Merch from './components/Merch';
import Navbar from './components/layout/Navbar';
import SocialSidebar from './components/SocialSidebar';
import Sponsors from './components/Sponsors';
import Tours from './components/Tours';
import ContactForm from './components/helpers/ContactForm';

function App() {
  return (
    <MDBContainer className="app-container" fluid>
      <SocialSidebar />
      <Header />
      <Navbar />

      <MDBContainer className="logos-card-container">
        <MDBRow className="gap-5">
          <MDBCol>
            <Sponsors />
          </MDBCol>
          <MDBCol>
            <Tours />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <About />
      <Merch />
      <ContactForm />
      <Footer />
    </MDBContainer>
  );
}

export default App;
