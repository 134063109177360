import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import InfoDialog from '../helpers/InfoDialog';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import kwikFireLogo from '../../media/Kwikfire-only.jpg';
import { Link } from 'react-scroll';
import { styled } from '@mui/system';
import { MDBBtn, MDBContainer, MDBIcon } from 'mdb-react-ui-kit';

const Navbar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [isDialogOpen, setDialogState] = useState(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const CustomBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem',
      marginBottom: '1rem',
    },
  }));

  return (
    <AppBar position="sticky" className="navbar-root">
      <MDBContainer fluid className="toolbar-container">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              className="toolbar-icon-btn"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              keepMounted={false}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              onClick={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <Box sx={{ display: 'block' }}>
                <div className="navbar-menu-text">
                  <Link to="home" className="link main-font navbar-text" smooth>
                    Home
                  </Link>
                </div>
                <div className="navbar-menu-text">
                  <Link
                    to="tours"
                    className="link main-font navbar-text"
                    smooth
                  >
                    Tours
                  </Link>
                </div>
                <div className="navbar-menu-text">
                  <Link
                    to="sponsors"
                    className="link main-font navbar-text"
                    smooth
                    sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                    Sponsors
                  </Link>
                </div>
                <div className="navbar-menu-text">
                  <Link
                    sx={{ my: 2, color: 'white', display: 'block' }}
                    to="merchandise"
                    className="link main-font navbar-text"
                    smooth
                  >
                    Merchandise
                  </Link>
                </div>
                <div className="navbar-menu-text">
                  <Link
                    to="lessons"
                    className="link main-font navbar-text"
                    smooth
                  >
                    Lessons
                  </Link>
                </div>
              </Box>
            </Menu>
          </Box>

          <div>
            <Box
              component="img"
              sx={{
                maxHeight: '60px',
                maxWidth: '150px',
                padding: '7px',
                // objectFit: 'cover',
              }}
              src={kwikFireLogo}
            />
          </div>
          <MDBBtn
            floating
            tag="a"
            color="none"
            onClick={() => setDialogState(true)}
          >
            <MDBIcon fas icon="trophy" className="trophy-icon" size="xl" />
          </MDBBtn>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'end',
            }}
          >
            <CustomBox>
              <Link to="home" className="link main-font navbar-text" smooth>
                Home
              </Link>
              <Link to="tours" className="link main-font navbar-text" smooth>
                Tours
              </Link>
              <Link
                to="sponsors"
                className="link main-font navbar-text"
                smooth
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                Sponsors
              </Link>
              <Link
                sx={{ my: 2, color: 'white', display: 'block' }}
                to="merchandise"
                className="link main-font navbar-text"
                smooth
              >
                Merchandise
              </Link>
              <Link to="lessons" className="link main-font navbar-text" smooth>
                Lessons
              </Link>
            </CustomBox>
          </Box>
        </Toolbar>
      </MDBContainer>
      <InfoDialog isDialogOpen={isDialogOpen} setDialogState={setDialogState} />
    </AppBar>
  );
};

export default Navbar;
