import React from 'react';
import {
  MDBCard,
  // MDBCardImage,
  // MDBCardBody,
  // MDBCardTitle,
  // MDBCardText,
  // MDBCardFooter,
  MDBBtn,
  MDBCardHeader,
  MDBContainer,
  MDBRow,
} from 'mdb-react-ui-kit';
import { MDBIframe } from "mdbreact";
import { projects } from '../constants/project.js';

const merchandise = projects.filter((pr) => pr.title === 'Merchandise')[0];

export default function Merch() {
  return (
    <MDBContainer fluid className="merch-card-container" id={merchandise.id}>
      <MDBContainer breakpoint="md">
        <MDBRow className="gap-3">
          {/* <MDBCardGroup>
            {merchandise.image.map(({ img, url, title, price }) => (
              <MDBCard key={title}>
              <MDBCardImage
              className="signed-merch-img"
              src={img}
              alt={title}
              position="top"
              />
              <MDBCardBody>
              <MDBCardTitle className="text-center">{title}</MDBCardTitle>
              <MDBCardText></MDBCardText>
              </MDBCardBody>
              <MDBCardFooter className="text-end">
              <small className="text-muted text-end">{`Price: $${price}`}</small>
              </MDBCardFooter>
              </MDBCard>
              ))}
            </MDBCardGroup> */}
        </MDBRow>
        <MDBRow >
          <MDBCard className='merch-header-card'>
            <MDBCardHeader className="merch-header sp-card-header d-flex">
              <h2 className="main-font">Merchandise</h2>
              <MDBBtn style={{ backgroundColor: '#0d2b4d' }} target='_blank' href='https://www.steverauprobilliards.com/s/order?shipping=true#ZBACMMM3H4ZIZGFDJBH37JSY'>
              <span className="main-font steve-btn">Go to website</span>
              </MDBBtn>
            </MDBCardHeader>
            <MDBIframe className='merch-iframe-div' ratio="4by3" src="https://www.steverauprobilliards.com/s/order?shipping=true#ZBACMMM3H4ZIZGFDJBH37JSY" />
          </MDBCard>
        </MDBRow>
      </MDBContainer>
    </MDBContainer>
  );
}
