import React from 'react';
import { projects } from '../constants/project.js';
import {
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
} from 'mdb-react-ui-kit';

const sponsors = projects.filter((pr) => pr.title === 'Sponsors')[0];

export default function Sponsors() {
  return (
    <MDBContainer className=" text-center justify-content-center">
      <MDBCard alignment="center" id={sponsors.id}>
        <MDBCardHeader className="sp-card-header">
          <h2 className="main-font">Sponsors</h2>
        </MDBCardHeader>
        <MDBCardBody>
          {sponsors.image.map(({ img, url, title }) => (
            <a href={url} target="_blank" rel="noreferrer">
              <img
                src={img}
                alt={title}
                style={{ objectFit: 'contain' }}
                className={`${title}-img sponsor-img`}
              />
            </a>
          ))}
          {/* <MDBBtn href="#">Button</MDBBtn> */}
        </MDBCardBody>
        {/* <MDBCardFooter className="text-muted">2 days ago</MDBCardFooter> */}
      </MDBCard>
      <MDBRow className="mb-1"></MDBRow>
    </MDBContainer>
  );
}
