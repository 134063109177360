import React from 'react';
import { MDBCarousel, MDBCarouselItem, MDBContainer } from 'mdb-react-ui-kit';
import { projects } from '../constants/project';

const aboutMe = projects.filter((pr) => pr.title === 'About me')[0];

const About = () => {
  return (
    <MDBContainer fluid className="about-me-container text-center">
      <MDBCarousel
        showControls
        showIndicators
        fade
        dealy={3000}
        className="card-main-container rounded-4"
        id={aboutMe.id}
      >
        {aboutMe.image.map(({ img, url, title }, idx) => (
          <MDBCarouselItem
            className="w-100 about-me-img"
            key={title + idx}
            itemId={idx + 1}
            src={img}
            alt={title}
          >
            <h5>{title}</h5>
            {/* <p>{aboutMe.description}</p> */}
          </MDBCarouselItem>
        ))}
      </MDBCarousel>
    </MDBContainer>
  );
};

export default About;
