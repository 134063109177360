import wpbaLogo from '../media/tours/wpba.png';
import proSeriesLogo from '../media/tours/proseries-dark.webp';
import worldGames from '../media/photos/wg_group.jpg';
import chinaCyclop from '../media/photos/china-2.jpg';
import world10ballAus from '../media/photos/w10-final.jpg';
import jamUpApparel from '../media/sponsors/jamup2.png';
import horoTips from '../media/sponsors/horo-tips.png';
import xingjueLogo from '../media/sponsors/xingjue.jpg';
import predatorCues from '../media/sponsors/predator.png';
import cueball from '../media/merch/cueball.jpeg';
import jersey from '../media/merch/jersey_kf.webp';
import signedPic from '../media/merch/signed-pic.png';

export const projects = [
  {
    title: 'About me',
    description: 'Hi I am Kelly.',
    image: [
      { img: worldGames, url: '', title: 'World Games 2022, USA' },
      { img: chinaCyclop, url: '', title: 'Exhibition match, China' },
      {
        img: world10ballAus,
        url: '',
        title: 'World 10-ball Championships 2022, Austria',
      },
      // {
      //   img: world9ballAC,
      //   url: '',
      //   title: 'World 9-ball Championships 2023, USA',
      // },
    ],
    tags: [],
    source: '',
    flex: 1,
    id: 'home',
  },
  {
    title: 'Tours',
    description: 'Where I travel...',
    image: [
      // { img: nineballLogo, url: 'https://matchroompool.com/' },
      { img: wpbaLogo, url: 'https://wpba.com/events-official' },
      { img: proSeriesLogo, url: 'https://probilliardseries.com/#' },
    ],
    tags: ['9-ball', '10-ball'],
    source: '',
    flex: 2,
    id: 'tours',
  },
  {
    title: 'Sponsors',
    description: `They've helped me get where I am now!`,
    image: [
      {
        img: predatorCues,
        url: 'https://www.predatorcues.com/int/pro-team/fisher-kelly.html',
      },
      {
        img: xingjueLogo,
        url: 'https://www.szxjbilliards.com/',
      },
      {
        img: jamUpApparel,
        url: 'https://jamupapparel.com/?ref=Kwikfire',
      },
      {
        img: horoTips,
        url: 'https://www.horotips.com/',
      },
    ],
    tags: [],
    source: '',
    flex: 2,
    id: 'sponsors',
  },
  {
    title: 'Merchandise',
    description: 'Expand your collection.',
    image: [
      {
        img: cueball,
        url: '',
        title: 'Signed Cue Ball',
        price: 30,
      },
      {
        img: jersey,
        url: '',
        title: 'Signed Jersey',
        price: 80,
      },
      {
        img: signedPic,
        url: '',
        title: 'Signed Photo',
        price: 15,
      },
    ],
    tags: [],
    source: '',
    flex: 1,
    id: 'merchandise',
  },
];
